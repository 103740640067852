import React, {useEffect, useState} from 'react';
import axios from "axios";

const TaskDetailsModal = ({taskToSee, setOpenDetailsModal, setTaskToSee}) => {
    const html = taskToSee.description;
    const parsedHtml = React.createElement('div', {dangerouslySetInnerHTML: {__html: html}});
    const [userMail, setUserMail] = useState("")
    let taskImages = [];
    if (taskToSee.images) {
        taskImages = JSON.parse(taskToSee.images);
    }
    useEffect(() => {
        axios.get(`http://tasks.maplaque-nfc-link.fr/tasks/app.php?getUserMail=getUserMail&name=${taskToSee.user}`)
            .then(response => {
                console.log(response.data.email)
                setUserMail(response.data.email);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, []);
    return (
        <div className='details_modal'>
            <div className="details">
                {
                    taskToSee.urgent == 1 ?
                        <h1 style={{color: 'red'}}>URGENT</h1>
                        : null
                }
                <h2>{taskToSee.name}</h2>
                <small style={{opacity: '0.8', whiteSpace: 'pre-line'}}><em>{parsedHtml}</em></small>
                {
                    taskImages.length > 0 ?
                        <div className="images">
                            <h3>Pièces jointes</h3>
                            <div className="images-container"
                                 style={{backgroundColor: '#e3e3e3', borderRadius: '10px'}}>
                                {
                                    taskImages.map((image, index) => (
                                        !image.endsWith('.pdf') ?
                                            <a href={'http://tasks.maplaque-nfc-link.fr/tasks/uploads/' + image}
                                               target='_blank' rel="noreferrer"><img
                                                style={{height: '75px', objectFit: 'cover', objectPosition: 'top'}}
                                                src={'http://tasks.maplaque-nfc-link.fr/tasks/uploads/' + image}
                                                alt={`Pic `}/></a>
                                            : <a href={'http://tasks.maplaque-nfc-link.fr/tasks/uploads/' + image}
                                                 target='_blank' rel="noreferrer"><img
                                                style={{height: '75px', objectFit: 'cover', objectPosition: 'top'}}
                                                src='./img/pdf.png' alt={`Pic `}/></a>
                                    ))
                                }
                            </div>
                        </div>
                        : null
                }
                {
                    taskToSee.table_id == '-1' || taskToSee.table_id == '-2' || taskToSee.table_id == 0 ?
                        <>
                            <p>Nom du client
                                : <strong>{taskToSee.client_name !== '' && taskToSee.client_name !== null ? taskToSee.client_name : 'non renseigné'}</strong>
                            </p>
                            <p>Email du client
                                : <strong>{taskToSee.client_email !== '' && taskToSee.client_email !== null ? taskToSee.client_email : 'non renseigné'}</strong>
                            </p>
                            <p>Téléphone du client
                                : <strong>{taskToSee.client_phone !== '' && taskToSee.client_email !== null ? taskToSee.client_phone : 'non renseigné'}</strong>
                            </p>
                            <p>Date limite
                                : <strong>{taskToSee.client_deadline !== '0000-00-00' || taskToSee.client_deadline == "" || taskToSee.client_deadline == null ? taskToSee.client_deadline : 'non renseigné'}</strong>
                            </p>

                        </>
                        : null
                }
                <p>Ajouté par <strong>{taskToSee.user} - {userMail}</strong></p>
                <p>Crée le : {taskToSee.created_at}</p>
                {
                    taskToSee.updated_at !== '0000-00-00 00:00:00' ?
                        <p>
                            {
                                taskToSee.old_table_id &&
                                taskToSee.old_table_id == 6 ?
                                    'Envoyé à l\'impression le'
                                    :
                                    taskToSee.table_id == localStorage.getItem('archived_table') ?
                                        'Archivé le '
                                        : 'Modifié le '
                            } : {taskToSee.updated_at}</p>
                        : null
                }
                <p onClick={() => {
                    setOpenDetailsModal(false)
                    setTaskToSee([])
                }}>Fermer</p>
            </div>
            <div className="opacity_modal"></div>
        </div>
    );
};

export default TaskDetailsModal;