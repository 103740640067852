import React, { useState } from 'react';
import TableList from '../components/TableList';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const Tasks = () => {
    const navigate = useNavigate();

    const [openAddUserModal, setOpenAddUserModal] = useState('');

    const [name, setName] = useState('');

    const [email, setEmail] = useState('');

    const [password, setPassword] = useState('');

    const [addedUser, setAddedUser] = useState(false);

    const logout = () => {
        localStorage.removeItem('id');
        localStorage.removeItem('name');
        localStorage.removeItem('token');
        navigate('/');
    }

    return (
        <div className="ui">
            <nav className='navbar app' >
                <img style={{ width: '150px' }} src="./img/logo-web.svg" alt="" />
                {
                    localStorage.getItem('role') === 'admin' ?
                        <p style={{ marginLeft: 'auto', marginRight: '30px', fontSize: '16px', color: '#ffc107', cursor: 'pointer', textAlign: 'center' }} onClick={() => setOpenAddUserModal(true)}>Ajt user</p>
                        : null
                }
                <p style={{ marginLeft: 'auto', marginRight: '30px', fontSize: '16px', color: '#2fc2e2', cursor: 'pointer' }} onClick={() => {
                    logout()
                }}>Deconnexion</p>
            </nav>
            <TableList addedUser={addedUser} />
            <div className="opacity"></div>
            {
                openAddUserModal && localStorage.getItem('role') === 'admin' ?
                    <div className="addUserModal">
                        <form onSubmit={(e) => {
                            if (localStorage.getItem('role') === 'admin') {
                                e.preventDefault();
                                axios.post('http://tasks.maplaque-nfc-link.fr/tasks/app.php', {
                                    name: name,
                                    password: password,
                                    email: email,
                                    addUser: 'addUser',
                                }, {
                                    headers: {
                                        'Content-Type': 'application/x-www-form-urlencoded'

                                    }
                                })
                                    .then((response) => {
                                        setOpenAddUserModal(false);
                                        setPassword('');
                                        setName('');
                                        setEmail('');
                                        setAddedUser(true)
                                    })
                                    .catch(error => {
                                        console.error('Error fetching data:', error);
                                    });
                            }
                        }}>
                            <div>
                                <label htmlFor="name">Nom</label>
                                <br />
                                <input type="text" id='name' onChange={(e) => setName(e.target.value)} />
                            </div>
                            <br />
                            <div>
                                <label htmlFor="email">Email</label>
                                <br />
                                <input type="email" id='email' onChange={(e) => setEmail(e.target.value)} />
                            </div>
                            <br/>
                            <div>
                                <label htmlFor="password">Mot de passe</label>
                                <br />
                                <input type="password" id='password' onChange={(e) => setPassword(e.target.value)} />
                            </div>
                            <br />
                            <button>Ajouter</button>
                            <br />
                            <br />
                            <button onClick={(e) => {
                                e.preventDefault();
                                setOpenAddUserModal(false)
                            }}>Annuler</button>
                        </form>
                        <div className="opacity"></div>
                    </div>
                    : null
            }

        </div>
    );
};

export default Tasks;