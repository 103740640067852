import React, {useEffect, useState} from 'react';
import TableItem from './TableItem';
import AddTask from './AddTask';
import axios from 'axios';
import UpdateTask from './UpdateTask';
import TaskDetailsModal from './TaskDetailsModal';
import ItemTask from './ItemTask';
import AddTables from './AddTables';


const TableList = ({addedUser}) => {
    const [openForm, setOpenForm] = useState(false);

    const [tableItems, setTableItems] = useState([]);

    const [taskAdded, setTaskAdded] = useState(false);

    const [tableAdded, setTableAdded] = useState(false);

    const [updateTaskModal, setUpdateTaskModal] = useState(false);

    const [taskToUp, setTaskToUp] = useState([]);

    const [openDetailsModal, setOpenDetailsModal] = useState(false);

    const [taskToSee, setTaskToSee] = useState('');

    const [seeArchived, setSeeArchived] = useState(false);

    const [tablesFiltered, setTablesFiltered] = useState([]);

    const [searchText, setSearchText] = useState('');

    const [allTasks, setAllTasks] = useState([]);

    const [allTasksFiltered, setAllTasksFiltered] = useState([]);

    const [seeTaskFiltered, setSeeTaskFiltered] = useState(false);

    const [openAddModal, setOpenAddModal] = useState(false);

    const [users, setUsers] = useState([]);

    const [user, setUser] = useState(localStorage.getItem('id'));

    const [archivedUserTable, setArchivedUserTable] = useState(localStorage.getItem('archived_table'));

    const [firstTable, setFirstTable] = useState(localStorage.getItem('first_table'));


    const [seeInterconnectTable, setSeeInterconnectTable] = useState(false);

    useEffect(() => {
        axios.get(`http://tasks.maplaque-nfc-link.fr/tasks/app.php?action=getTablesByUser&user_id=${user}`)
            .then(response => {
                setTableItems(response.data);
                const filteredTables = response.data.filter((item) => item.id != archivedUserTable)
                setTablesFiltered(filteredTables);
                setTableAdded(false);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });


    }, [tableAdded, user]);
    useEffect(() => {
        if (localStorage.getItem('role') === 'admin') {
            axios.get(`http://tasks.maplaque-nfc-link.fr/tasks/app.php?action=getAllUsers`)
                .then(response => {
                    setUsers(response.data);
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                });
        }
    }, [addedUser])
    useEffect(() => {
        const fetchData = () => {
            axios.get(`http://tasks.maplaque-nfc-link.fr/tasks/app.php?action=getAllTasks&user_id=${user}`)
                .then(response => {
                    setAllTasks(response.data);
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                });
        };

        fetchData();
        const interval = setInterval(() => {
            fetchData();
        }, 15000);

        return () => {
            clearInterval(interval);
        };
    }, [taskAdded, seeArchived, user]);

    const openModal = () => {
        if (openForm) {
            setOpenForm(false)
        } else {

            setOpenForm(true)
        }
    }

    const seeArchive = () => {
        setSeeArchived(true)
        setSeeInterconnectTable(false)
        const filteredTables = tableItems.filter((item) => item.id == archivedUserTable)
        setTablesFiltered(filteredTables);
    }
    const seeAll = () => {
        setSeeArchived(false)
        setSeeInterconnectTable(false)
        const filteredTables = tableItems.filter((item) => item.id != archivedUserTable)
        setTablesFiltered(filteredTables);
    }

    const seeInterconnect = () => {
        setSeeInterconnectTable(true)
        setSeeArchived(false)
        const filteredTables = tableItems.filter((item) => item.id <= 0)
        setTablesFiltered(filteredTables);
    }

    return (
        <>
            <nav className="navbar board" style={{display: 'flex', justifyContent: 'center', gap: '30px'}}>
                <p style={{padding: '10px 10px', backgroundColor: '#ffc107', borderRadius: '5px', cursor: 'pointer'}}
                   onClick={() => {
                       setOpenAddModal(true)
                   }}><small>+ table</small></p>
                <small>
                    <p style={{
                        padding: '10px 10px',
                        backgroundColor: '#2fc2e2',
                        borderRadius: '5px',
                        cursor: 'pointer'
                    }} onClick={() => {
                        openModal(true)
                    }}>+ tache</p>
                </small>
                {
                    !seeArchived ?
                        <p style={{
                            padding: '10px 10px',
                            backgroundColor: '#dc3545',
                            borderRadius: '5px',
                            cursor: 'pointer'
                        }} onClick={() => {
                            seeArchive()
                        }}><small>Archivé</small></p>
                        :
                        <p style={{
                            padding: '10px 10px',
                            backgroundColor: '#dc3545',
                            borderRadius: '5px',
                            cursor: 'pointer'
                        }} onClick={() => {
                            seeAll()
                        }}><small>Voir tout</small></p>
                }
                {
                    !seeInterconnectTable ?
                        <p style={{
                            padding: '10px 10px',
                            backgroundColor: '#dc3545',
                            borderRadius: '5px',
                            cursor: 'pointer'
                        }} onClick={() => {
                            seeInterconnect()
                        }}><small>Interco</small></p>
                        :
                        <p style={{
                            padding: '10px 10px',
                            backgroundColor: '#dc3545',
                            borderRadius: '5px',
                            cursor: 'pointer'
                        }} onClick={() => {
                            seeAll()
                        }}><small>Voir tout</small></p>
                }

            </nav>
            <div className="fitler_user">
                {
                    localStorage.getItem('role') === 'admin' ?
                        <form>
                            <label htmlFor='user'>Filtrer par utilisateur</label>
                            <select name="user" id="user" onChange={(e) => {
                                const user = users[e.target.value];
                                setUser(user.id)
                                setArchivedUserTable(user.archived_table);
                                setFirstTable(user.first_table)
                            }}>
                                <option selected disabled>-- Choisissez un utilisateur</option>
                                {
                                    users.map((item, i) => (
                                        <option value={i} key={i}>

                                            {
                                                item.id == localStorage.getItem('id') ?
                                                    'Mon espace'
                                                    : item.name
                                            }
                                        </option>
                                    ))
                                }
                            </select>
                        </form>
                        : null
                }
            </div>

            <div className="search" style={{margin: '10px auto', width: '300px'}}>
                <input style={{
                    borderRadius: '10px',
                    padding: '3px',
                    border: '3px solid #2fc2e2',
                    margin: 'auto',
                    width: '250px',
                    display: 'block'
                }} type="text" placeholder='Rechercher une tache' onChange={(e) => {
                    setSearchText(e.target.value)
                    const tasksFiltered = allTasks.filter((item) => item.name.toLowerCase().includes(searchText.toLowerCase()))
                    setAllTasksFiltered(tasksFiltered);
                    setSeeTaskFiltered(true);

                    if (e.target.value === '') {
                        setSeeTaskFiltered(false);
                    }
                }}/>
                {
                    seeTaskFiltered ? <div className="list">
                            <header>Resultat</header>
                            <ul>
                                {
                                    allTasksFiltered.map((task, i) => (
                                        <ItemTask task={task} key={i} tableItems={tableItems} setTableItems={setTableItems}
                                                  setTaskAdded={setTaskAdded} setTaskToUp={setTaskToUp}
                                                  setUpdateTaskModal={setUpdateTaskModal} setTaskToSee={setTaskToSee}
                                                  setOpenDetailsModal={setOpenDetailsModal}
                                                  archivedUserTable={archivedUserTable}/>
                                    ))
                                }
                            </ul>
                            <footer onClick={() => setSeeTaskFiltered(false)}>Fermer</footer>
                        </div>
                        : null
                }

            </div>
            <div className="lists">
                {
                    tablesFiltered.length > 0 ?
                        tablesFiltered.map((item, i) => (
                            !seeInterconnectTable ?
                                item.id > 0 ?
                                    <TableItem key={i} item={item} tableItems={tableItems} setTableItems={setTableItems}
                                               openModal={openModal} taskAdded={taskAdded} setTaskAdded={setTaskAdded}
                                               setTaskToUp={setTaskToUp} setUpdateTaskModal={setUpdateTaskModal}
                                               setTaskToSee={setTaskToSee} setOpenDetailsModal={setOpenDetailsModal}
                                               seeArchived={seeArchived} setTableAdded={setTableAdded}
                                               firstTable={firstTable} archivedUserTable={archivedUserTable}/>
                                    : null
                                :
                                <TableItem key={i} item={item} tableItems={tableItems} setTableItems={setTableItems}
                                           openModal={openModal} taskAdded={taskAdded} setTaskAdded={setTaskAdded}
                                           setTaskToUp={setTaskToUp} setUpdateTaskModal={setUpdateTaskModal}
                                           setTaskToSee={setTaskToSee} setOpenDetailsModal={setOpenDetailsModal}
                                           seeArchived={seeArchived} setTableAdded={setTableAdded}
                                           firstTable={firstTable} archivedUserTable={archivedUserTable}/>
                        ))

                        : <p>Aucune tables pour le moment !</p>
                }
                {
                    openForm ?
                        <AddTask tableItems={tableItems} setTableItems={setTableItems} setOpenForm={setOpenForm}
                                 setTaskAdded={setTaskAdded}/>
                        : null
                }
                {
                    updateTaskModal ?
                        <UpdateTask tableItems={tableItems} taskToUp={taskToUp} setUpdateTaskModal={setUpdateTaskModal}
                                    setTaskAdded={setTaskAdded} setTaskToUp={setTaskToUp}/>
                        : null
                }
                {
                    openDetailsModal ?
                        <TaskDetailsModal taskToSee={taskToSee} setOpenDetailsModal={setOpenDetailsModal}
                                          setTaskToSee={setTaskToSee}/>
                        : null
                }
                {
                    openAddModal ?
                        <AddTables setOpenAddModal={setOpenAddModal} setTableAdded={setTableAdded} user={user}/>
                        : null
                }
            </div>
        </>
    );
};

export default TableList;