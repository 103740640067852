import React from 'react';
import axios from 'axios';

const ItemTask = ({ task, tableItems, setTableItems, setTaskAdded, setTaskToUp, setUpdateTaskModal, setTaskToSee, setOpenDetailsModal, archivedUserTable }) => {
    const deleteTask = (task) => {

        axios.get(`http://tasks.maplaque-nfc-link.fr/tasks/app.php?action=deleteTask&id_task=${task}`)
            .then(response => {
                console.log('Tache supprimé');
                setTaskAdded(true);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }
    let taskImages = [];
    if (task.images) {
        taskImages = JSON.parse(task.images);
    }

    return (
        <li>
            {
                taskImages.length > 0 ?
                    !taskImages[0].endsWith('.pdf') ?
                        <a href={'http://tasks.maplaque-nfc-link.fr/tasks/uploads/' + taskImages[0]} target='_blank' rel="noreferrer"><img style={{ height: '75px', objectFit: 'cover', objectPosition: 'top' }} src={'http://tasks.maplaque-nfc-link.fr/tasks/uploads/' + taskImages[0]} alt={`Pic `} /></a>
                        : <a href={'http://tasks.maplaque-nfc-link.fr/tasks/uploads/' + taskImages[0]} target='_blank' rel="noreferrer"><img style={{ height: '75px', objectFit: 'cover', objectPosition: 'top' }} src='./img/pdf.png' alt={`Pic `} /></a>
                    : null
            }
            <p style={{ cursor: 'pointer' }} onClick={() => {
                setTaskToSee(task)
                setOpenDetailsModal(true)
            }}><strong>{task.name}
                    {
                        archivedUserTable == task.table_id ? 
                            <em> - (Archivé)</em>
                        : null
                    }
                    {task.urgent == 1 ? <svg style={{ fill: 'red' }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-flag-fill" viewBox="0 0 16 16">
                        <path d="M14.778.085A.5.5 0 0 1 15 .5V8a.5.5 0 0 1-.314.464L14.5 8l.186.464-.003.001-.006.003-.023.009a12.435 12.435 0 0 1-.397.15c-.264.095-.631.223-1.047.35-.816.252-1.879.523-2.71.523-.847 0-1.548-.28-2.158-.525l-.028-.01C7.68 8.71 7.14 8.5 6.5 8.5c-.7 0-1.638.23-2.437.477A19.626 19.626 0 0 0 3 9.342V15.5a.5.5 0 0 1-1 0V.5a.5.5 0 0 1 1 0v.282c.226-.079.496-.17.79-.26C4.606.272 5.67 0 6.5 0c.84 0 1.524.277 2.121.519l.043.018C9.286.788 9.828 1 10.5 1c.7 0 1.638-.23 2.437-.477a19.587 19.587 0 0 0 1.349-.476l.019-.007.004-.002h.001" />
                    </svg> : null}</strong></p>
            {
                task.description ? <svg style={{ margin: '0 10px 0 0' }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-card-text" viewBox="0 0 16 16">
                    <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z" />
                    <path d="M3 5.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3 8a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 8zm0 2.5a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5z" />
                </svg> : null
            }
            {
                taskImages.length > 0 ?
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-paperclip" viewBox="0 0 16 16">
                        <path d="M4.5 3a2.5 2.5 0 0 1 5 0v9a1.5 1.5 0 0 1-3 0V5a.5.5 0 0 1 1 0v7a.5.5 0 0 0 1 0V3a1.5 1.5 0 1 0-3 0v9a2.5 2.5 0 0 0 5 0V5a.5.5 0 0 1 1 0v7a3.5 3.5 0 1 1-7 0V3z" />
                    </svg>
                    : null
            }
            <span className='update_task' onClick={() => {
                setTaskToUp(task);
                setUpdateTaskModal(true);
            }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pen" viewBox="0 0 16 16">
                    <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001zm-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708l-1.585-1.585z" />
                </svg>
            </span>
            <span className='delete_task' onClick={() => {
                if (window.confirm(`Vous etes sur le point de supprimer la tache ${task.name} ! Etes vous sur ?`)) {
                    deleteTask(task.id)
                }
            }}><svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512" style={{ fill: 'red' }}><path d="M170.5 51.6L151.5 80h145l-19-28.4c-1.5-2.2-4-3.6-6.7-3.6H177.1c-2.7 0-5.2 1.3-6.7 3.6zm147-26.6L354.2 80H368h48 8c13.3 0 24 10.7 24 24s-10.7 24-24 24h-8V432c0 44.2-35.8 80-80 80H112c-44.2 0-80-35.8-80-80V128H24c-13.3 0-24-10.7-24-24S10.7 80 24 80h8H80 93.8l36.7-55.1C140.9 9.4 158.4 0 177.1 0h93.7c18.7 0 36.2 9.4 46.6 24.9zM80 128V432c0 17.7 14.3 32 32 32H336c17.7 0 32-14.3 32-32V128H80zm80 64V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V192c0-8.8 7.2-16 16-16s16 7.2 16 16zm80 0V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V192c0-8.8 7.2-16 16-16s16 7.2 16 16zm80 0V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V192c0-8.8 7.2-16 16-16s16 7.2 16 16z" /></svg></span>
        </li>
    );
};

export default ItemTask;
