import Routes from './routes'
import './styles/App.scss'

function App() {
  return (
    <>
      <Routes />
    </>
  );
}

export default App;
