import React, { useEffect, useState } from 'react';
import ItemTask from './ItemTask';
import axios from 'axios';

const TableItem = ({ item, tableItems, setTableItems, openModal, taskAdded, setTaskAdded, setTaskToUp, setUpdateTaskModal, setTaskToSee, setOpenDetailsModal, seeArchived, setTableAdded, firstTable, archivedUserTable }) => {

    const [tasks, setTasks] = useState([]);

    useEffect(() => {
        
        const fetchData = () => {
            axios.get(`http://tasks.maplaque-nfc-link.fr/tasks/app.php?action=getTasksTables&id=${item.id}`)
                .then(response => {
                    setTasks(response.data);
                    setTaskAdded(false);
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                });
        };

        fetchData();
        const interval = setInterval(() => {
            fetchData();
        }, 15000);

        return () => {
            clearInterval(interval);
        };
    }, [taskAdded, seeArchived, tableItems]);

    const deletetable = (id) => {

        axios.get(`http://tasks.maplaque-nfc-link.fr/tasks/app.php?action=deleteTable&id_table=${id}`)
            .then(response => {
                setTableAdded(true);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }

    return (
        <div className='list' style={{ position: 'relative' }}>
            <header>{item.name}</header>
            {
                firstTable != item.id && archivedUserTable != item.id && item.id != 0 && item.id != '-1' && item.id != '-2' ?
                    <span className='delete_task' style={{ position: 'absolute', top: '5px', right: '5px', cursor: 'pointer' }} onClick={() => {
                        if (window.confirm(`Vous etes sur le point de supprimer la table ${item.name} et toutes les taches qu'elle contient ! Etes vous sur ?`)) {
                            deletetable(item.id)
                        }
                    }}><svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512" style={{ fill: 'red' }}><path d="M170.5 51.6L151.5 80h145l-19-28.4c-1.5-2.2-4-3.6-6.7-3.6H177.1c-2.7 0-5.2 1.3-6.7 3.6zm147-26.6L354.2 80H368h48 8c13.3 0 24 10.7 24 24s-10.7 24-24 24h-8V432c0 44.2-35.8 80-80 80H112c-44.2 0-80-35.8-80-80V128H24c-13.3 0-24-10.7-24-24S10.7 80 24 80h8H80 93.8l36.7-55.1C140.9 9.4 158.4 0 177.1 0h93.7c18.7 0 36.2 9.4 46.6 24.9zM80 128V432c0 17.7 14.3 32 32 32H336c17.7 0 32-14.3 32-32V128H80zm80 64V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V192c0-8.8 7.2-16 16-16s16 7.2 16 16zm80 0V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V192c0-8.8 7.2-16 16-16s16 7.2 16 16zm80 0V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V192c0-8.8 7.2-16 16-16s16 7.2 16 16z" /></svg></span>
                    : null
            }

            <ul>
                {
                    !tasks ? <p>Loading </p> :  tasks.map((task, i) => (
                        task.urgent == 1 ?
                            task.table_id == item.id ?
                                <ItemTask task={task} key={i} tableItems={tableItems} setTableItems={setTableItems} setTaskAdded={setTaskAdded} setTaskToUp={setTaskToUp} setUpdateTaskModal={setUpdateTaskModal} setTaskToSee={setTaskToSee} setOpenDetailsModal={setOpenDetailsModal} />
                                : null
                            : null
                    ))
                }
                {
                    tasks.map((task, i) => (
                        task.urgent == 0 ?
                            task.table_id == item.id ?
                                <ItemTask task={task} key={i} tableItems={tableItems} setTableItems={setTableItems} setTaskAdded={setTaskAdded} setTaskToUp={setTaskToUp} setUpdateTaskModal={setUpdateTaskModal} setTaskToSee={setTaskToSee} setOpenDetailsModal={setOpenDetailsModal} />
                                : null
                            : null
                    ))
                }
            </ul>
            <footer onClick={() => openModal()}>Ajouter une tache </footer>
        </div>
    );
};

export default TableItem;