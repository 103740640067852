import React, { useState } from 'react';
import axios from 'axios';

const AddTables = ({ setOpenAddModal, setTableAdded, user }) => {
    const [name, setName] = useState('');
    const [inter, setInter] = useState(false);
    console.log(name);
    return (
        <div className='add_table_modal'>
            <form onSubmit={(e) => {
                e.preventDefault();
                axios.get(`http://tasks.maplaque-nfc-link.fr/tasks/app.php?action=addTable&name=${name}&user_id=${user}&inter=${inter}`)
                    .then(response => {
                        setOpenAddModal(false);
                        setTableAdded(true);
                    })
                    .catch(error => {
                        console.error('Error fetching data:', error);
                    });
            }}>
                <label htmlFor="name">Nom de la table</label>
                <br />
                <br />
                <input type="text" onChange={(e) => setName(e.target.value)} />
                <br />
                <br />
                <label htmlFor="inter">Table interconnecté</label>
                <input type="checkbox" id="inter" onChange={(e) => setInter(e.target.checked)}/>
                <br />
                <br />
                <button>Ajouter</button>
                <br />
                <br />
                <button onClick={(e) => {
                    e.preventDefault();
                    setOpenAddModal(false);
                }}>Annuler</button>
            </form>
            <div className="opacity"></div>
        </div>
    );
};

export default AddTables;