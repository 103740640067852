import React, {useState} from 'react';
import axios from 'axios';

const AddTask = ({tableItems, setTableItems, setOpenForm, setTaskAdded}) => {

    const [tableId, setTableId] = useState('10');
    const [taskName, setTaskName] = useState('');
    const [taskDesc, setTaskDesc] = useState('');
    const [checkbox, setCheckbox] = useState(0);
    const [errorMsg, setErrorMsg] = useState('');
    const [images, setImages] = useState([]);
    const userName = localStorage.getItem('name');
    const id = localStorage.getItem('id');
    const [clientName, setClientName] = useState('');
    const [clientEmail, setClientEmail] = useState('');
    const [clientPhone, setClientPhone] = useState('');
    const [clientDeadline, setClientDeadline] = useState('');


    return (
        <div className='add_modal'>
            <form className='add_task_modal' action="" style={{margin: '50px'}} onSubmit={(e) => {
                e.preventDefault();
                if (tableId !== '' && taskName !== '' && localStorage.getItem('token') !== '') {
                    axios.post('http://tasks.maplaque-nfc-link.fr/tasks/app.php', {
                        name: taskName,
                        description: taskDesc,
                        table_id: tableId,
                        urgent: checkbox,
                        user: userName,
                        userId: id,
                        addTask: 'addTask',
                        image: images,
                        clientName: clientName,
                        clientEmail: clientEmail,
                        clientPhone: clientPhone,
                        clientDeadline: clientDeadline
                    }, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                        .then((response) => {
                            setTableId('');
                            setTaskName('');
                            setTaskDesc('');
                            setErrorMsg('');
                            setOpenForm(false);
                            setTaskAdded(true);
                        })
                        .catch(error => {
                            console.error('Error fetching data:', error);
                        });
                } else {
                    setErrorMsg('Veuillez remplir obligatoirement les champs "Choisir une table" et "Nom de la tache"')
                }
            }}>
                <h2>Ajouter une tache a un tableau</h2>
                {
                    errorMsg !== '' ?
                        <p style={{color: 'red'}}>{errorMsg}</p>
                        : null
                }
                <select name="name" id="name" onChange={(e) => setTableId(e.target.value)}>
                    <option disabled selected>--- Choisissez une table ---</option>
                    {
                        tableItems.map((item, i) => (
                            <option key={i} value={item.id}>{item.name}</option>
                        ))
                    }
                </select>
                <div style={{margin: '20px 0'}}>
                    <label htmlFor="task">Nom de la tache</label>
                    <br/>
                    <input type="text" id="task" defaultValue={taskName} onChange={(e) => setTaskName(e.target.value)}/>
                </div>
                <div style={{margin: '20px 0'}}>
                    <label htmlFor="desc">Description de la tache</label>
                    <br/>
                    <textarea name="" id="desc" defaultValue={taskDesc} onChange={(e) => setTaskDesc(e.target.value)}
                              cols="30" rows="10"></textarea>
                </div>
                {
                    tableId == '-1' || tableId == '-2' || tableId == 0 ?
                        <>
                            <div style={{margin: '20px 0'}}>
                                <label htmlFor="client_name">Nom du client</label>
                                <br/>
                                <input type="text" id="client_name" defaultValue={clientName}
                                       onChange={(e) => setClientName(e.target.value)}/>
                            </div>
                            <div style={{margin: '20px 0'}}>
                                <label htmlFor="client_email">E-mail du client</label>
                                <br/>
                                <input type="text" id="client_email" defaultValue={clientEmail}
                                       onChange={(e) => setClientEmail(e.target.value)}/>
                            </div>
                            <div style={{margin: '20px 0'}}>
                                <label htmlFor="client_phone">Numéro du client</label>
                                <br/>
                                <input type="text" id="client_phone" defaultValue={clientPhone}
                                       onChange={(e) => setClientPhone(e.target.value)}/>
                            </div>
                            <div style={{margin: '20px 0'}}>
                                <label htmlFor="client_deadline">Date limite</label>
                                <br/>
                                <input type="date" id="client_deadline" defaultValue={clientDeadline}
                                       onChange={(e) => setClientDeadline(e.target.value)}/>
                            </div>

                        </>

                        : null

                }
                <div>
                    <label htmlFor="checkbox">Urgent ?</label>
                    <input type="checkbox" name="checkbox" id="checkbox" onClick={(e) => {
                        if (checkbox == 1) {
                            setCheckbox(0);
                        } else {
                            setCheckbox(1);
                        }
                    }}/>
                </div>
                <br/>
                <div>
                    <label htmlFor="img">Images</label>
                    <input type="file" name="img" id="img" multiple onChange={(e) => {
                        setImages([...e.target.files]);
                    }}/>
                </div>
                <br/>
                <button>Ajouter</button>
                <button style={{margin: '10px'}} onClick={(e) => {
                    e.preventDefault();
                    setOpenForm(false);
                }}>Annuler
                </button>
            </form>
            <div className="opacity_modal"></div>
        </div>
    );
};

export default AddTask;