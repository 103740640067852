import React from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import Layout from '../layouts/Layout';
import Tasks from '../pages/Tasks';
import Login from '../pages/Login';


const index = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route index element={<Login />} />
                <Route element={<Layout />}>
                    <Route path='/tasklist' element={<Tasks />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
};

export default index;